<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备配置</el-breadcrumb-item>
      <el-breadcrumb-item>网络录像机配置</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'cameraDvr-add'">
          <el-button type="primary" size="small" @click="addCameraDvr">新增网络录像机</el-button>
          &nbsp;
        </div>
            <el-table
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        header-row-class-name="my-table-head-style"
        height="100%"
                v-loading="spinning"
                :data="data"
                row-key="id"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form label-position="left" class="demo-table-expand">
                    <el-form-item :label="'绑定摄像头'+(index+1)+':'" v-for="(item,index) in props.row.child" :key="index">
                      <span style="margin-left: 30px">摄像头名称: <a-tag color="pink">{{  item.cameraName}}</a-tag></span>
                      <span style="margin-left: 50px">摄像头通道: <a-tag color="pink">{{  item.informationId}}</a-tag></span>
                    </el-form-item>
                    <!--<el-form-item label="摄像头通道">
                      <span>{{ props.row.child.informationId }}</span>
                    </el-form-item>-->
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column
                  prop="serialNumber"
                  label="序列号">
              </el-table-column>
              <el-table-column
                  prop="inNum"
                  label="录像机规格">
                <template slot-scope="scope">
                  {{scope.row.inNum+'路'}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="capacity"
                  label="硬盘容量">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  width="200"
                  label="操作"
                  align="center"
                  v-if="authVerify.verify('cameraDvr-edit,cameraDvr-delete')"
              >
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit-outline" size="mini" v-auth="'cameraDvr-edit'"
                             @click="editCameraDvrData(scope.row)">编辑
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" v-auth="'cameraDvr-delete'"
                             @click="deleteCameraDvrData(scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
            title="新增网络录像机"
            width="400"
            placement="right"
            :closable="false"
            :visible="addCameraDvrVisible"
            @close="addCameraDvrVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="CameraDvrFormValue" ref="cameraDvrRefFormAdd"
                          :rules="CameraDvrRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="序列号" prop="serialNumber">
                <a-input
                    v-model="CameraDvrFormValue.serialNumber"
                />
              </a-form-model-item>
              <a-form-model-item label="录像机规格" prop="inNum">
                <a-select  style="width: 100%" placeholder="请选择" v-model="CameraDvrFormValue.inNum">
                  <a-select-option v-for="(i,index) in 32" :key="i">
                    {{ i +'路'}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="硬盘容量" prop="capacity">
                <a-input
                    v-model="CameraDvrFormValue.capacity"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addCameraDvrVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toAddCameraDvr">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
            title="编辑网络录像机"
            width="400"
            placement="right"
            :closable="false"
            :visible="editCameraDvrVisible"
            @close="editCameraDvrVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="CameraDvrFormValue" ref="cameraDvrRefFormEdit"
                          :rules="CameraDvrRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="序列号" prop="serialNumber">
                <a-input
                    v-model="CameraDvrFormValue.serialNumber"
                />
              </a-form-model-item>
              <a-form-model-item label="录像机规格" prop="inNum">
                <a-select  style="width: 100%" placeholder="请选择" v-model="CameraDvrFormValue.inNum">
                  <a-select-option v-for="(i,index) in 32" :key="i">
                    {{ i +'路'}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="硬盘容量" prop="capacity">
                <a-input
                    v-model="CameraDvrFormValue.capacity"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editCameraDvrVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toEditCameraDvr">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                CameraDvrFormValue: {
                    id: '',
                    serialNumber: '',
                    inNum: undefined,
                    capacity: '',
                },
                CameraDvrRules: {
                    serialNumber: [
                        {required: true, message: '请输入序列化!', trigger: 'blur'},
                    ], inNum: [
                        {required: true, message: '请输入录像机规格!', trigger: 'blur'},
                    ], capacity: [
                        {required: true, message: '请输入硬盘容量!', trigger: 'blur'},
                    ],
                },
                spinning: true,
                screenWidth: document.documentElement.clientWidth,//屏幕宽度
                screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
                addCameraDvrVisible: false,
                editCameraDvrVisible: false,
                data: [],
            }
        },
        watch: {
            'screenWidth': function (val) { //监听屏幕宽度变化
                console.log(val);
            },
            'screenHeight': function (val) { //监听屏幕高度变化
                console.log(val);
            },
        },
        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
            };
        },
        created() {
            this.getCameraDvrData();
        },
        methods: {
            getCameraDvrData() {
                this.http.camera.getCameraNetworkDvrList()
                    .then(response => {
                        if (response.data.status === 200) {
                            this.spinning = false;
                            this.data = response.data.data;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            deleteCameraDvrData(key) {
                let self = this;
                this.$confirm({
                    title: '确定删除吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.http.camera.deleteCameraNetworkDvr({
                            id: key.id
                        })
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    self.getCameraDvrData();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            })
                    },
                    onCancel() {
                    },
                });
            },

            addCameraDvr() {
                this.cleanDTU();
                this.addCameraDvrVisible = true;
                  this.$refs.cameraDvrRefFormAdd.resetFields()
            },
            toAddCameraDvr() {
                this.$refs.cameraDvrRefFormAdd.validate(valid => {
                    if (valid) {
                        console.log(this.CameraDvrFormValue);
                        this.http.camera.createCameraNetworkDvr(this.CameraDvrFormValue).then(response => {
                            if (response.data.status === 200) {
                                this.$message.success(response.data.msg);
                                this.addCameraDvrVisible = false;
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                            this.getCameraDvrData();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editCameraDvrData(record) {
                console.log(record);
                this.cleanDTU();
                this.CameraDvrFormValue = {
                    id: record.id,
                    serialNumber: record.serialNumber,
                    inNum: record.inNum,
                    capacity: record.capacity,
                };
                this.editCameraDvrVisible = true;
                  this.$refs.cameraDvrRefFormEdit.resetFields()
            },
            cleanDTU() {
                this.CameraDvrFormValue = {
                    id: '',
                    serialNumber: '',
                    inNum: undefined,
                    capacity: '',
                };
            },

            toEditCameraDvr() {
                this.$refs.cameraDvrRefFormEdit.validate(valid => {
                    if (valid) {
                        this.http.camera.updateCameraNetworkDvr(this.CameraDvrFormValue)
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    this.editCameraDvrVisible = false;
                                } else {
                                    this.$message.warning(response.data.msg);
                                }
                                this.getCameraDvrData();
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

        },
    }
</script>

<style scoped>
  /*滚动条整体样式*/
  .scroll_bar::-webkit-scrollbar {
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  }

  .scroll_bar::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #cecece;
  }

  .scroll_bar::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  .demo-table-expand >>> .el-form-item__label {
    color: #99a9bf;
    font-size: 8px;
  }

  .demo-table-expand >>> .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
